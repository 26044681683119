export var widthProperty = ['width', 'w'];
export var heightProperty = ['height', 'h'];
export var chartDataProperty = ['chartData', 'chd'];
export var chartCategoryProperty = ['chartCategory', 'c'];
export var chartSeriesProperty = ['chartSeries', 'cs'];
export var chartLegendProperty = ['chartLegend', 'cl'];
export var chartPrimaryCategoryAxisProperty = ['chartPrimaryCategoryAxis', 'cpca'];
export var chartPrimaryValueAxisProperty = ['chartPrimaryValueAxis', 'cpva'];
export var chartTitleProperty = ['chartTitle', 'ctt'];
export var chartTypeProperty = ['chartType', 'ct'];
export var trendLinesProperty = ['trendLines', 'tl'];
export var dataPointsProperty = ['dataPoints', 'dp'];
export var seriesNameProperty = ['seriesName', 'sn'];
export var dataLabelProperty = ['dataLabel', 'sl'];
export var errorBarProperty = ['errorBar', 'eb'];
export var fillProperty = ['fill', 'f'];
export var lineProperty = ['line', 'l'];
export var rgbProperty = ['rgb', 'rgb'];
export var foreColorProperty = ['foreColor', 'fc'];
export var positionProperty = ['position', 'p'];
export var typeProperty = ['type', 't'];
export var nameProperty = ['name', 'n'];
export var directionProperty = ['direction', 'dir'];
export var endStyleProperty = ['endStyle', 'est'];
export var forwardProperty = ['forward', 'fw'];
export var backwardProperty = ['backward', 'bw'];
export var interceptProperty = ['intercept', 'itr'];
export var categoryTypeProperty = ['categoryType', 'ct'];
export var hasMajorGridLinesProperty = ['hasMajorGridLines', 'hmajgl'];
export var hasMinorGridLinesProperty = ['hasMinorGridLines', 'hmingl'];
export var majorUnitProperty = ['majorUnit', 'maju'];
export var maximumValueProperty = ['maximumValue', 'maxv'];
export var minimumValueProperty = ['minimumValue', 'minv'];
export var categoryXNameProperty = ['categoryXName', 'cx'];
export var numberFormatProperty = ['numberFormat', 'nf'];
export var yValueProperty = ['yValue', 'y'];
export var sizeProperty = ['size', 'sz'];
export var seriesFormatProperty = ['seriesFormat', 'sf'];
